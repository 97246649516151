import $ from 'jquery';
import Velocity from 'velocity-animate';
import bootstrap from 'bootstrap';
import fontawesome from '@fortawesome/fontawesome';
import regular from '@fortawesome/fontawesome-free-regular';
import solid from '@fortawesome/fontawesome-free-solid';
import brands from '@fortawesome/fontawesome-free-brands';
import 'jquery-match-height';

$(document).ready(function () {
    $('.carousel-item').matchHeight();

    let header = $('header');
    let mainSectionHeight = $('#main').height();
    $(window).on('scroll', function (e) {
        // console.log('scroll')
        if ($(document).scrollTop() > mainSectionHeight - header.height()) {
            header.fadeIn();
        } else {
            header.fadeOut();
        }
    }).trigger('scroll');

    $(window).on('resize', function (e) {
        mainSectionHeight = $('#main').height();
        $(this).trigger('scroll');
    });

    $($('.nav-link').map(function () {
        if ($(this).attr('href') && $(this).attr('href')[0] === '#') return this;
    }).get()).on('click', function (ev) {
        ev.preventDefault();
        let element = $($(this).attr('href'));
        if ($(this).parents('#navbarSecondary').length) {
            $(this).parents('#navbarSecondary').on('hidden.bs.collapse', function (e) {
                let that = this;
                Velocity($('body'), 'scroll', {
                    offset: element.offset().top - header.height() + 1,
                    duration: 1000,
                    easing: 'ease-in-out',
                    begin() {
                        ev.stopImmediatePropagation();
                    },
                    complete() {
                        $(that).off('hidden.bs.collapse');
                    },
                });
            });
        } else {
            Velocity($('html'), 'scroll', {
                offset: element.offset().top - header.height() + 1,
                duration: 1000,
                easing: 'ease-in-out'
            });
        }
    });

    $('.scroll-down').on('click', function (e) {
        // console.log($('.nav-link').filter('[href="#about"]').get(0));
        $($('.nav-link').filter('[href="#about"]').get(0)).trigger('click');
    });

    $.fn.extend({
        toggleMenuIcon() {
            $(this).siblings('.navbar-toggler').find('.fa-bars, .fa-times').toggleClass('fa-times fa-bars');
        }
    });

    $('.navbar-collapse').on('show.bs.collapse', function (e) {
        $(this).toggleMenuIcon();
    }).on('hide.bs.collapse', function (e) {
        $(this).toggleMenuIcon();
    }).find('.nav-link').on('click', function (e) {
        $(this).parents('.navbar-collapse').collapse('hide');
    });
});
